<template>
  <div class="page flex-col align-center">
    <div class="backTop">
      <img src="@/assets/img/screenCooperateDpLogo.png" alt="" />
      <div class="txt">一款针对LED大屏的广告自助投放软件</div>
      <div class="launch-btn flex-col" id="primary-btn" @click="onServe">
        立即合作
      </div>
    </div>
    <div class="box flex-col align-center">
      <div class="title-view flex-col align-center" style="margin-top: 60rem">
        <span class="span1">— 广告客户可以使用大屏时代实现 —</span>
        <span class="span2"
          >从视频制作到下单投屏的全流程一站式自助广告投放解决方案</span
        >
      </div>
      <div class="listBox">
        <div
          :class="{
            'list-item': index < 8 ? true : false,
            'list-item2': index >= 8 ? true : false,
          }"
          v-for="(item, index) in textList"
          :key="index"
        >
          <div class="top align-center">
            <img :src="item.img" alt="" />
            <span>{{ item.title }}</span>
          </div>
          <span class="bottom"
            >{{ item.describe
            }}<a v-if="item.a" :href="item.href" target="_blank">{{
              item.a
            }}</a></span
          >
        </div>
      </div>
      <div class="title-view flex-col align-center">
        <span class="span1">— 商务合作 —</span>
        <span class="span2">大屏时代为您提供最优解决方案</span>
      </div>
      <div class="teamwork flex-row justify-between">
        <div class="teamwork-item flex-col align-center">
          <div class="img_box">
            <img src="@/assets/img/teamwork1.png" alt="" />
          </div>
          <div class="title">软件租赁</div>
          <div class="text-box flex-col">
            <div class="Bot_text align-center">
              <i class="dot"></i>付费使用软件，您自主运营
            </div>
            <div class="Bot_text align-center">
              <i class="dot"></i>软件版本免费升级
            </div>
            <div class="Bot_text align-center">
              <i class="dot"></i>广告收益归您
            </div>
          </div>
        </div>
        <div class="teamwork-item flex-col align-center">
          <div class="img_box">
            <img src="@/assets/img/teamwork2.png" alt="" />
          </div>
          <div class="title">屏幕合作</div>
          <div class="text-box flex-col">
            <div class="Bot_text align-center">
              <i class="dot"></i>免费使用软件，我方运营
            </div>
            <div class="Bot_text align-center">
              <i class="dot"></i>软件使用和软件版本免费升级
            </div>
            <div class="Bot_text align-center">
              <i class="dot"></i>广告收益和我们进行分成
            </div>
          </div>
        </div>
      </div>
      <div class="title-view flex-col align-center" ref="scrollDiv">
        <span class="span1">— 联系我们 —</span>
        <span class="span2">如需合作请联系我们，我们将竭诚为您服务</span>
      </div>
      <div class="foot justify-between">
        <div class="foot-left flex-col">
          <div class="information align-center">
            <img src="@/assets/img/foot1.png" alt="" />
            <span>深圳市南山区高新南七道惠恒大厦二期708</span>
          </div>
          <div class="information align-center">
            <img src="@/assets/img/foot3.png" alt="" />
            <span>support@daping.cn</span>
          </div>
          <div class="information align-center">
            <img src="@/assets/img/foot2.png" alt="" />
            <span>400-998-9272（周一至周六：9:00-20:00）</span>
          </div>
          <div class="information link-txt align-center">
            <img src="@/assets/img/foot4.png" alt="" />
            <span @click="downloadPdf">大屏时代屏幕合作.PDF</span>
          </div>
          <div class="information link-txt align-center">
            <img src="@/assets/img/foot5.png" alt="" />
            <span @click="goDemoLogin">使用演示账号体验</span>
          </div>
        </div>
        <div class="foot-right flex-col">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="ruleForm"
          >
            <el-form-item prop="phone">
              <div class="form-item align-center">
                <div class="label">手机号</div>
                <el-input
                  type="number"
                  placeholder="请输入"
                  v-model="ruleForm.phone"
                  oninput="if(value.length > 11) value=value.slice(0, 11)"
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item prop="verifyCode">
              <div class="form-item align-center">
                <div class="label">验证码</div>
                <el-input
                  type="number"
                  placeholder="验证码"
                  v-model="ruleForm.verifyCode"
                  oninput="if(value.length > 6) value=value.slice(0, 6)"
                >
                </el-input>
                <el-button
                  v-if="isSendStataus"
                  type="text"
                  @click="sendCode"
                  :class="!isSend ? 'sendAgainMsg' : 'sendMsg'"
                  :disabled="!isSend || validateFieldPhone"
                  >重新发送</el-button
                >
                <el-button
                  v-else
                  class="sendMsg"
                  type="text"
                  @click="sendCode"
                  :disabled="validateFieldPhone"
                  >发送验证码</el-button
                >
                <span v-if="!isSend" class="sendNum">{{ sendNum }}s</span>
              </div>
            </el-form-item>
          </el-form>
          <div class="group align-center" style="margin-top: 25rem">
            <div class="label">合作方式</div>
            <el-select v-model="ruleForm.content" placeholder="请选择">
              <el-option
                v-for="item in contentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="launch-btn flex-col"
            id="primary-btn"
            @click="$throttle(onContactUs)"
          >
            预约咨询
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { contactUs, getVerifyCode } from "@/api/request";

const checkPhone = (rule, value, callback) => {
  const reg =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};

const checkCode = (rule, value, callback) => {
  const reg = /^\d{6}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的验证码"));
  } else {
    callback();
  }
};
export default {
  data() {
    return {
      sendNum: 60,
      isSend: true,
      isSendStataus: false,
      validateFieldPhone: true,
      verifyId: "",
      ruleForm: {
        phone: "",
        content: "",
        verifyId: "",
        verifyCode: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            validator: checkCode,
            message: "请输入正确的验证码",
            trigger: "blur",
          },
        ],
      },
      contentOptions: [
        {
          value: "软件租赁",
          label: "软件租赁",
        },
        {
          value: "屏幕合作",
          label: "屏幕合作",
        },
      ],
      textList: [
        {
          img: require("@/assets/img/about2.png"),
          title: "指定时间",
          describe: "用户可以根据自己的需求，自主预约广告播出的时间。",
        },
        {
          img: require("@/assets/img/about3.png"),
          title: "指定屏幕",
          describe: "用户可以根据自己的需求，自主选择哪个LED大屏进行广告投放。",
        },
        {
          img: require("@/assets/img/about4.png"),
          title: "在线下单",
          describe:
            "用户可以在线下单，全流程无需人工干预。订单审核通过后，系统会按照订单的指令自动播放广告视频。",
        },
        {
          img: require("@/assets/img/about5.png"),
          title: "分享订单",
          describe:
            "用户可以将自己的订单分享给他人，被分享人打开分享页面同样能看到该订单广告投放时间表等信息。",
          a: "查看订单分享示例",
          href: "https://www.daping.cn/share?code=dd8277a6edc54c2ba306fed9489c70a2",
        },
        {
          img: require("@/assets/img/about6.png"),
          title: "小程序投放",
          describe:
            "用户可以通过“大屏时代极速版”微信小程序进行广告投放，随时随地提交投放订单，操作简单、方便快捷。",
        },
        {
          img: require("@/assets/img/about7.png"),
          title: "智能投放",
          describe:
            "用户仅需一次设置，系统可连续多天多次投放广告，且每次优先为用户选择高峰时段进行投放。",
        },
        {
          img: require("@/assets/img/about8.png"),
          title: "大屏监控（可选）",
          describe:
            "摄像头实时拍摄现场大屏画面，用户可远程查看正在投放的广告，并可截图或录像。此外，还可监控屏幕是否正常运行并发出警报。",
          a: "查看大屏监控",
          href: "https://www.daping.cn/p2pVisitor/",
        },
        {
          img: require("@/assets/img/about11.png"),
          title: "离线模式",
          describe:
            "若您的屏幕不便联网，可使用“离线模式”。只需每天在屏幕关机后，去联网一次，同步未来24小时的广告订单。“联网模式”和“离线模式”可自由切换。",
        },
        {
          img: require("@/assets/img/about9.png"),
          title: "企业或个人都可以投",
          describe:
            "企业可投放广告视频让品牌或产品获得更多曝光量；个人可投放表白、祝福等视频让爱的TA上大屏。",
        },
        {
          img: require("@/assets/img/about10.png"),
          title: "自定义轮播招商/公益视频",
          describe:
            "没有客户投放广告的空闲时段，将播放屏幕合作方的招商视频，不限制上传的招商视频个数，另外我们提供数十个公益视频，可选择招商/公益视频混播，大屏将进行轮播。",
        },
      ],
    };
  },
  watch: {
    ruleForm: {
      deep: true,
      handler(v) {
        // console.log(v.phone);
        const regPhone =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!regPhone.test(v.phone)) {
          this.validateFieldPhone = true;
        } else {
          this.validateFieldPhone = false;
        }
      },
    },
  },
  methods: {
    sendCode() {
      if (!this.isSend) {
        return;
      }
      if (!this.ruleForm.phone) {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
          center: true,
        });
        return;
      }
      const regPhone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!regPhone.test(this.ruleForm.phone)) {
        return;
      }
      const userPhoneCode = `86${this.ruleForm.phone}`;
      getVerifyCode({
        phone: userPhoneCode,
        style: 18,
      }).then((res) => {
        this.verifyId = res.verifyId;
        this.ruleForm.verifyCode = "";
        let timer = 60;
        this.sendNum = timer;
        this.isSendStataus = true;
        this.timeFun = setInterval(() => {
          timer -= 1;
          this.sendNum = timer;
          if (timer === 0) {
            this.isSend = true;
            clearInterval(this.timeFun);
          }
        }, 1000);
        this.isSend = false;
      });
    },
    onServe() {
      const scrollElem = this.$refs.scrollDiv;
      window.scrollTo({
        top: scrollElem.offsetTop - 100,
        behavior: "smooth",
      });
    },
    onContactUs() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.verifyId) {
            this.$message({
              message: "请先获取验证码",
              type: "warning",
              center: true,
            });
            return false;
          }
          this.ruleForm.verifyId = this.verifyId;
          const params = { ...this.ruleForm };
          if (!params.content) {
            params.content = " ";
          }
          contactUs(params)
            .then(() => {
              this.$message({
                message:
                  "预约成功！请您耐心等待，我们会尽快与您联系，您也可以直接拨打客服电话进行咨询：400-998-9272",
                type: "success",
                duration: 5000,
                center: true,
              });
              this.sendNum = 60;
              this.isSend = true;
              this.isSendStataus = false;
              clearInterval(this.timeFun);
              this.ruleForm.verifyId = "";
              this.ruleForm.verifyCode = "";
            })
            .catch((errCode) => {
              console.log("错误信息", errCode);
            });
        } else {
          return false;
        }
      });
    },
    downloadPdf() {
      fetch(
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/pdf/dpScreenCooperate.pdf"
      )
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "大屏时代屏幕合作.pdf"; // 设置下载文件的名称
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    },
    goDemoLogin() {
      window.open(`https://demo.daping.cn/demoLogin`);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  background: #fbfdff;
  min-height: calc(100vh - 233rem);
  padding-bottom: 100rem;
  .backTop {
    width: 100%;
    height: 576rem;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../assets/img/screenCooperateTop.png");
    background-size: 100% 100%;
    img {
      width: 214rem;
      margin: 180rem 0 0 320rem;
    }
    .txt {
      margin: 15rem 0 0 320rem;
      font-size: 20rem;
      color: rgba(45, 45, 45, 0.65);
      line-height: 28rem;
      letter-spacing: 6rem;
    }
    .launch-btn {
      width: 184rem;
      height: 54rem;
      text-align: center;
      line-height: 54rem;
      border-radius: 12rem;
      cursor: pointer;
      transition: all 0.3s;
      margin: 40rem 0 0 320rem;
      font-size: 20rem !important;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .box {
    width: 1280rem;
    margin-top: 506rem;
    .title-view {
      width: 100%;
      margin-top: 100rem;
      .span1 {
        font-size: 28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2d2d2d;
        line-height: 40rem;
      }
      .span2 {
        font-size: 20rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(45, 45, 45, 0.65);
        line-height: 28rem;
        margin-top: 22rem;
      }
    }

    .listBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 35rem;

      .list-item,
      .list-item2 {
        width: 296rem;
        border-radius: 12rem;
        background: #fdfdfd;
        box-shadow: 0px 5rem 38rem 0px rgba(197, 197, 197, 0.18);
        background: url("../../assets/img/cooperationCardBg.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        padding: 30rem 20rem 20rem;
        margin-top: 40rem;

        .top {
          img {
            width: 35rem;
            height: 35rem;
          }
          span {
            font-size: 18rem;
            color: #2bb3e3;
            line-height: 25rem;
            letter-spacing: 1rem;
            margin-left: 10rem;
          }
        }
        .bottom {
          font-size: 14rem;
          color: #a7a7a7;
          line-height: 22rem;
          margin-top: 15rem;
          a {
            color: #2bb3e3;
          }
        }

        .right {
          width: 280rem;

          .title {
            height: 25rem;
            font-size: 18rem;
            font-weight: 500;
            color: #2bb3e3;
            line-height: 25rem;
          }

          span {
            margin-top: 6rem;
            display: inline-block;
            width: 280rem;
            height: 66rem;
            font-size: 14rem;
            font-weight: 500;
            color: #a7a7a7;
            line-height: 22rem;
            a {
              color: rgba(43, 179, 227, 1);
            }
          }
        }
      }
      .list-item2 {
        width: 624rem;
        padding: 30rem 19rem 20rem;
      }
    }
    .teamwork {
      width: 100%;
      margin-top: 75rem;
      .teamwork-item {
        width: 624rem;
        box-shadow: 0px 5rem 38rem 0px rgba(197, 197, 197, 0.18);
        border-radius: 20rem;
        background: url("../../assets/img/cooperationCardBg.png");
        background-size: 100% 100%;
        padding: 50rem 0;
        .img_box {
          width: 72rem;
          height: 72rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          font-size: 18rem;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #2bb3e3;
          line-height: 25rem;
          margin-top: 18rem;
          margin-bottom: 13rem;
        }
        .text-box {
          .Bot_text {
            font-size: 14rem;
            color: #6f6f6f;
            line-height: 22rem;
            margin-top: 5rem;

            .dot {
              margin-right: 5rem;
              width: 6rem;
              height: 6rem;
              border-radius: 50%;
              background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
            }
          }
        }
      }
    }
    .foot {
      width: 100%;
      margin-top: 75rem;
      .foot-left {
        width: 624rem;
        background: #fdfdfd;
        box-shadow: 0px 5rem 38rem 0px rgba(197, 197, 197, 0.18);
        border-radius: 20rem;
        background: url("../../assets/img/cooperationCardBg.png");
        background-size: 100% 100%;
        padding: 63rem 0 60rem 0;
        .information {
          margin-top: 20rem;
          margin-left: 143rem;
          &:nth-child(1) {
            margin-top: 0;
          }
          img {
            width: 24rem;
            height: 24rem;
            margin-right: 15rem;
          }
          span {
            font-size: 16rem;
            color: #2d2d2d;
            line-height: 29rem;
          }
        }
        .link-txt {
          margin-top: 20rem;
          span {
            font-size: 16rem;
            color: #2bb3e3;
            line-height: 29rem;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .foot-right {
        width: 624rem;
        background: #fdfdfd;
        box-shadow: 0px 5rem 38rem 0px rgba(197, 197, 197, 0.18);
        border-radius: 20rem;
        background: url("../../assets/img/cooperationCardBg.png");
        background-size: 100% 100%;
        padding: 23rem 0 48rem;
        .ruleForm {
          width: 385rem;
          margin-left: 129rem;
          .form-item {
            position: relative;
            margin-top: 25rem;
            .label {
              width: 97rem;
              text-align: left;
              font-size: 18rem;
              color: rgba(45, 45, 45, 0.85);
              line-height: 25rem;
            }
            .sendMsg {
              position: absolute;
              right: 20rem;
              font-size: 16rem;
            }

            .sendAgainMsg {
              position: absolute;
              right: 50rem;
              font-size: 16rem;
            }

            .sendAgainMsg {
              color: #acaead;

              &:hover {
                color: #acaead;
              }
            }

            .sendNum {
              position: absolute;
              right: 15rem;
              color: #49aaf2;
              font-size: 16rem;
            }
          }
        }
        .group {
          margin-left: 129rem;
          .label {
            width: 97rem;
            text-align: left;
            font-size: 18rem;
            color: rgba(45, 45, 45, 0.85);
            line-height: 25rem;
          }
        }
        .launch-btn {
          width: 152rem;
          height: 41rem;
          text-align: center;
          line-height: 41rem;
          border-radius: 25rem;
          cursor: pointer;
          transition: all 0.3s;
          margin: 35rem 0 0 236rem;
          font-size: 18rem !important;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}

::v-deep .el-input__inner {
  width: 288rem;
  height: 49rem;
  border-radius: 27rem;
  border: 2rem solid rgba(0, 0, 0, 0.1);
  line-height: 49rem;
  font-size: 18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(45, 45, 45, 0.85);
}
::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: rgba(0, 0, 0, 0.1);
}
::v-deep .el-select .el-input__inner:focus {
  border-color: rgba(0, 0, 0, 0.1);
}
::v-deep .el-input__suffix {
  right: 14rem;
}
::v-deep .el-input__icon {
  line-height: 49rem;
}
::v-deep .el-input__inner::placeholder {
  font-size: 18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(45, 45, 45, 0.4);
}
::v-deep .el-select .el-input .el-select__caret {
  font-size: 18rem;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
::v-deep .el-form-item__error {
  font-size: 12rem;
  margin-top: 2rem;
  margin-left: 97rem;
  padding-top: 0rem;
}
</style>
